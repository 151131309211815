import style from "./ResultSection.module.css";

const ResultSection = (props) => {
  const title = props.result.title.slice(0, 70) + " ....";
  const thumb = props.result.thumbnail;

  return (
    <div className={style["result-div"]}>
      <div className={style["thumb-div"]}>
        <img src={thumb} alt="thumb"></img>
      </div>
      <h3> {title} </h3>
      <div className={style["download-section"]}>
        <table>
          <thead>
            <tr>
              <th>Resolution</th>
              <th>Quality</th>
              <th>Duration</th>
              <th>Size</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {props.result.videos.map((video) => (
              <tr key={video.url}>
                <td>{video.resolution}</td>
                <td>{video.quality}</td>
                <td>{video.duration}</td>
                <td>{video.size}</td>
                <td>
                  <a href={video.url} target="_blank" rel="noreferrer">
                    Download
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResultSection;
