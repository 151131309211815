import style from "./UserInput.module.css";
import InputSection from "./InputSection";
import ResultSection from "../ResultSection/ResultSection";
import Loader from "../UI/Loader";
import {useState} from "react";
import Error from "../UI/Error";

const UserInput = (props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoader, setLoader] = useState(false);
    const [isServerOk, setServerOk] = useState(true);
    const [urlResult, setUrlResult] = useState({
        description: "",
        duration: 0,
        ext: "",
        original_url: "",
        thumbnail: "",
        title: "",
        upload_date: "",
        videos: []
    });

    const userInputHandler = async (url, type) => {
        setUrlResult({
            description: "",
            duration: 0,
            ext: "",
            original_url: "",
            thumbnail: "",
            title: "",
            upload_date: "",
            videos: []
        });

        let urls = 'https://www.safegateway.site/fetch-info';

        const options = {
            method: "POST",
            headers: {
                "accept": "*/*",
                "content-type": "application/json",
            },
            body: JSON.stringify({
                url: url,
            }),
        };

        if (type === "error") {
            setServerOk(false);
            setErrorMessage(url);
        } else {
            setLoader(true);
            setServerOk(true);
            try {
                const response = await fetch(urls, options);
                const result = await response.json();
                setLoader(false);
                if (result.status === "fail") {
                    setServerOk(false);
                    setErrorMessage(result.error);
                } else {
                    setServerOk(true);
                    setUrlResult(result);
                }
            } catch (err) {
                setLoader(false);
                setServerOk(false);
                setErrorMessage(
                    "An unexpected error occurred. Please try again later."
                );
            }
        }
    };

    return (
        <div className={style["input-div"]}>
            <InputSection userUrls={userInputHandler}/>
            {isLoader && <Loader/>}
            {urlResult?.videos?.length > 0 && isServerOk && (
                <ResultSection result={{ ...urlResult, videos: [...new Map(urlResult.videos.map(video => [video.quality, video])).values()] }}/>
            )}
            {!isServerOk && <Error error={errorMessage}/>}
        </div>
    );
};

export default UserInput;
