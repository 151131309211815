import styles from "./Footer.module.css";
import brikmasLogo from "../../assets/brikmas-logo-48-transparent.png";
import appStore from "../../assets/app-store.png";
import playStore from "../../assets/google-play-store.png";

const Footer = () => {
  return (
      <footer className={styles["footer"]}>
          <a href="/">
              <p><u><span>Home</span></u></p>
          </a>

          <a href="/privacy-policy">
              <p><u><span>Privacy Policy</span></u></p>
          </a>

          <div className={styles["footer-store"]}>
              <a className={styles["app-store-img"]} href="https://play.google.com/store">
                  <img className={styles["large"]} src={playStore} alt="play-store"></img>
              </a>

              <a className={styles["app-store-img"]} href="https://www.apple.com/app-store/">
                  <img className={styles["large"]} src={appStore} alt="app-store"></img>
              </a>
          </div>
      </footer>
  );
};

export default Footer;
