import styles from "./App.module.css";
import Header from "./components/Layout/Header";
import React, {useEffect, Fragment} from "react";
import UserInput from "./components/UserInput/UserInput";
import IndexMessage from "./components/Layout/IndexMessage";
import Footer from "./components/Layout/Footer";
import IndexPrivacyPolicy from "./components/Layout/IndexPrivacyPolicy";
import IndexInvalidPage from "./components/Layout/IndexInvalidPage";

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

// Utility to update page titles dynamically
const usePageTitle = (title) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
};

// Wrapper component to handle page titles
const Page = ({title, children}) => {
    usePageTitle(title);
    return <>{children}</>;
};

function App() {
    return (
        <Router>
            <Fragment>
                <div className={styles["main-div"]}>
                    <Header/>
                    <Routes>
                        {/* Home Route */}
                        <Route
                            path="/"
                            element={
                                <Page title="Home">
                                    <IndexMessage/>
                                    <UserInput/>
                                </Page>
                            }
                        />

                        {/* Privacy Policy Route */}
                        <Route
                            path="/privacy-policy"
                            element={
                                <Page title="Privacy Policy">
                                    <IndexPrivacyPolicy/>
                                </Page>
                            }
                        />

                        {/* 404 Not Found Fallback */}
                        <Route
                            path="*"
                            element={
                                <Page title="404 Not Found">
                                    <IndexInvalidPage/>
                                </Page>
                            }
                        />
                    </Routes>
                </div>
                <Footer/>
            </Fragment>
        </Router>
    );
}

export default App;